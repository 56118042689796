import Swiper from 'swiper/bundle';
import Flickity from 'flickity';
import matchHeight from 'jquery-match-height';
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;


var bannerSwiperImage = new Swiper(".banner__swiper-image", {
    effect: "fade",
    loop: true,
    speed: 1000,
    simulateTouch: false,
    pagination: {
        el: ".banner__pagination",
    },
    autoplay: {
        delay: 8000,
        disableOnInteraction: false,
    },
});

var bannerSwiperTitle = new Swiper(".banner__swiper", {
    effect: "fade",
    loop: true,
    speed: 1000,
    simulateTouch: false,
    autoplay: {
        delay: 8000,
        disableOnInteraction: false,
    },
    pagination: {
        el: ".banner__pagination",
        clickable: true,
    },
    thumbs: {
        swiper: bannerSwiperImage,
    }
});

var testimonialSwiper = new Swiper(".testimonial__swiper", {
    spaceBetween: 30,
    simulateTouch: true,
    centeredSlides: true,
    slidesPerView: 1,
    loop: true,
    speed: 600,
    autoplay: {
        delay: 10000,
        disableOnInteraction: false,
    },
    breakpoints: {
        992: {
            simulateTouch: false,
            slidesPerView: 3,
        },
    },
    pagination: {
        el: ".testimonial__swiper-pagination",
    },
    navigation: {
        nextEl: ".testimonial__swiper-next",
        prevEl: ".testimonial__swiper-prev",
    },
});

// add banner height
function bannerHeight() {
    const bannerImageContainer = document.querySelector('.banner__image-container');
    let bannerImageContainerHeight = bannerImageContainer.offsetHeight;
    const bannerWrapper = document.querySelector('.banner__wrapper');
    
    bannerWrapper.style.height = bannerImageContainerHeight-125 + 'px';
    window.addEventListener('resize', () => {
        bannerWrapper.style.height = bannerImageContainerHeight-125 + 'px';
        console.log(bannerImageContainerHeight);
    });
}
// bannerHeight();

// programme section
function programmeAccordionImage() {
    // why kids section accordion images
    const collapseButton = document.querySelectorAll('.programme__collapse-button');
    collapseButton.forEach(item => {
        // select image element
        const imageElement = document.querySelector('.programme__image');

        item.addEventListener('click', event => {
            // get data image index attribute
            const imageUrl = item.getAttribute("data-image");

            // pass value into image element
            imageElement.src = imageUrl;
        });
    });
}
programmeAccordionImage()

document.addEventListener("DOMContentLoaded", function () {
    // matchHeight testimonial slider
    $(function() {
        $('.testimonial__swiper-content').matchHeight();  
    
        window.addEventListener('resize', () => {
            if (window.innerWidth < 768) {
                $('.testimonial__swiper-content').matchHeight();
            }       
        })
    })
})